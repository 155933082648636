import PropTypes from "prop-types";
import React from "react";
import LayoutWrapper from "@components/LayoutWrapper";
import Header from "@components/header";
import Hero from "@components/index/Hero";

function LayoutTemp({ children }) {
  return (
    <LayoutWrapper>
      <div className="flex md:min-h-screen self-center flex-col text-gray-900">
        <div>
          <Header />
        </div>
        <div
          className="flex-grow relative pt-16 pb-8 md:pb-0 md:pt-0 bg-darkbeige flex items-center justify-center flex-col 
        md:px-12"
        >
          <Hero />
        </div>
      </div>

      <div>
        <main className="main">{children}</main>
      </div>
    </LayoutWrapper>
  );
}

LayoutTemp.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutTemp;
