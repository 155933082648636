import React from "react";
import Welcome from "@components/index/Welcome";
import Fade from "react-reveal";
import config from "react-reveal/globals";

import profilePhoto from "@images/olle.jpg";
config({ ssrFadeout: true });

function Hero() {
  return (
    <>
      {useHasMounted && (
        <Fade bottom distance="30px">
          <div className="hero mx-auto flex items-center w-full self-center justify-center">
            <div className="flex flex-col md:flex-row flex-grow items-center justify-between md:max-w-5xl lg:max-w-6xl py-8 px-2 md:py-0">
              <Welcome />
              <div className="inline-block">
                <div className="w-full p-8">
                  <img
                    alt="Profile"
                    className="mx-auto rounded-full w-64 md:w-full mt-8 md:mt-0"
                    src={profilePhoto}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      )}
    </>
  );
}

export default Hero;

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}
