import React from "react";

import LayoutTemp from "../components/layout-temp";

import Work from "../components/work/Work";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <LayoutTemp>
      <SEO
        keywords={[
          `Olle`,
          `Burlin`,
          `Web designer`,
          `Webbdesigner`,

          `React`,
          `ReactJs`,
          `Wordpress`,
        ]}
        title="Olle Burlin - Webbdesigner och utvecklare i Malmö som gör snabba responsiva hemsidor"
      />

      <div className="bg-beige">
        <div className="pt-12 px-4 md:pt-24 max-w-4xl mx-auto" id="portfolio">
          <header className="pb-8 md:pb-12 text-center">
            <h2 className="font-bold text-2xl  md:text-3xl text-center inline tracking-normal">
              Projekt i urval
            </h2>
          </header>

          <Work />
        </div>
      </div>
    </LayoutTemp>
  );
}

export default IndexPage;
