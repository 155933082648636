import React from "react";
import { Link } from "gatsby";
function Welcome() {
  return (
    <section className="md:w-2/3 px-4 -mt-12 md:-mt-0">
      <div className="">
        <div className="text-4xl md:text-5xl font-bold tracking-tight mb-4">
          Hallå!
        </div>

        <div className="text-grey-700 text-xl md:text-2xl leading-snug">
          <p className="mb-4 md:mb-6">
            Mitt namn är <span className=" font-bold">Olle Burlin</span> och jag
            är en frilansande webbdesigner och utvecklare baserad i Malmö.
          </p>
          <p className="mb-4 md:mb-6">
            Jag skapar blixtsnabba, sökmotoroptimerade och responsiva hemsidor.
          </p>
          <p>
            Över 15 års branscherfarenhet av Wordpress och över 10 års erfarenhet
            av JavaScript-bibliotek som t.ex React.
            <div className="my-4 font-bold">
              <Link className="highlight hover:underline" to="/kontakt">
                Hör av dig!
              </Link>
            </div>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
