import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Project from "@components/work/Project";

function Work() {
  const data = useStaticQuery(graphql`
    query WorkQuery {
      allWordpressWpProjects {
        edges {
          node {
            title
            id
            slug
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              category
              description
              tools
              url
              slide_image_1 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              slide_image_2 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              slide_image_3 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className="flex flex-wrap flex-col overflow-hidden px-4">
      {data.allWordpressWpProjects.edges.map((project) => {
        return <Project key={project.node.id} project={project} />;
      })}
    </div>
  );
}

export default Work;
